var Compare = {

  el: '.compare',

  ui: {
    prevButton: '.compare__nav__btn.-prev',
    nextButton: '.compare__nav__btn.-next',
    items: '.compare__item',
    list: '.compare__list'
  },

  events: {
    'click {prevButton}': 'prev',
    'click {nextButton}': 'next'
  },

  order(number) {
    return `-ms-flex-order: ${number}; -webkit-order: ${number}; order: ${number};`
  },

  getFirstItem() {
    return this.ui.items.filter((i) => i.classList.contains('-faux-first'))[0];
  },

  getPrevItem(el) {
    var item = el || this.getFirstItem();
    if (item.previousElementSibling) {
      return item.previousElementSibling;
    } else {
      return this.ui.items[this.ui.items.length - 1];
    }
  },

  getNextItem(el) {
    var item = el || this.getFirstItem();
    if (item.nextElementSibling) {
      return item.nextElementSibling;
    } else {
      return this.ui.items[0];
    }
  },

  prev(e) {
    this.update(this.getPrevItem(), true);
  },

  next(e) {
    this.update(this.getNextItem(), false);
  },

  update(newItem, isReversing) {
    this.getFirstItem().classList.remove('-faux-first');

    var method = isReversing ? 'add' : 'remove';
    this.ui.list[0].classList[method]('-reversing');

    this.ui.items.forEach((i) => {
      i.classList.remove('-in');
      i.classList.remove('-out');
    });

    newItem.classList.add('-faux-first');
    if (!isReversing) {
      newItem.classList.add('-out');
    }
    newItem.setAttribute('style', this.order(1));

    for (var i = 2; i <= this.ui.items.length; i++) {
      newItem = this.getNextItem(newItem);
      newItem.setAttribute('style', this.order(i));

      if (i === 2) {
        newItem.classList.add('-in');
      }
      if (isReversing && i === 3) {
        newItem.classList.add('-out');
      }
    }

    this.ui.list[0].classList.remove('-set');
    return setTimeout((function () {
      return this.ui.list[0].classList.add('-set');
    }.bind(this)), 50);
  }

};

export default Compare;

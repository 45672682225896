import queryString from 'query-string';
import Cookies from 'cookies-js';
var params = queryString.parse(window.location.search);

export default {

  sources: ['s_src', 's_subsrc'],

  getSources: function (sources = this.sources) {
    return queryString.stringify(sources.reduce((obj, source) => {
      var value = Cookies.get(source);
      if (value) {
        obj[source] = value;
      }
      return obj;
    }, {}));
  },

  setSources: function (sources = this.sources) {
    sources.forEach((source) => {
      if (source in params) {
        Cookies.set(source, params[source]);
      }
    });
  }

};
